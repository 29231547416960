$primary-color: #234442; /* dark paint green */
$secondary-color: #e29ea9; /* light pink */
$secondary-color-2: #0f2117; /* dark green */
$logo-pink: #a77374; /* dark pink */
$logo-yellow: #d8bc73; /* mustard yellow */
$main-background-color: #fff; /* white */
$light-purple: #d4c2e7;
$light-gray: #d6d6d6;
$black: #000;
$teal: #22bdc4;
$body-font: "Lato";
$header-font: "PT sans";
$body-font-size: 16px;
$body-font-small: 14px;
$icon-size: 12px;
$header-font-size: 30px;
$secondary-header-font-size: 20px;
$nav-font-size: 18px;
$body-font-weight: 400;
$header-font-weight: 700;

* {
  box-sizing: border-box; /* Makes all elements fit on screen */
}

.brett-img__container {
  background: linear-gradient(
    0.1turn,
    #61406a 20%,
    rgba(166, 108, 182, 0.97),
    #dfa9eef7
  );
}

.storey-img__container {
  background: linear-gradient(0.89turn, #deaaed 0%, #74d9c9);
}

.timer-img__container {
  background: linear-gradient(0.1turn, #5d5d95 0%, #aea8eb);
}

.brett-homepage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.brett-mockup {
  z-index: 1;
  width: 50%;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.carousel-caption {
  bottom: -1.25rem;
}
/* Work page h1 */
.work-header {
  text-align: center;
  margin: 2rem 0;
}

.grid {
  display: grid;
  justify-items: center;
  padding: 3rem 0;
  max-width: 1400px;
  /* grid-template-columns: 300px 300px 300px; fractions are better than pixels - they are responsive */
  grid-template-columns: 1fr;
  grid-gap: 10rem;
}

.grid__item {
  display: flex;

  transition: transform 1s, opacity 1s;

  gap: 4rem;
}

.hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.reverse {
  flex-direction: row-reverse;
}

.grid__content p {
  margin: 0.8rem auto;
  padding-right: 2rem;
}

.grid__content-reverse {
  display: inline-flex;
  flex-direction: column;
  flex: 1 0 40%;
  text-align: left;
  align-items: flex-start;
  padding: 0 1rem;
  gap: 2rem;
}

.work_langs {
  padding-left: 0.5rem;
  width: 70%;
  background: linear-gradient(
    0.1turn,
    #5c0c73 20%,
    rgba(127, 19, 156, 0.97),
    rgb(183 12 230 / 97%)
  );
  box-shadow: 0 0 9px 0px rgb(108 19 133 /0.5);
  border-radius: 20px;
}

.work_langs p {
  padding: 0.8rem;
}

.work_langs-reverse {
  padding-right: 0.5rem;
  background: linear-gradient(
    0.9turn,
    #5c0c73 20%,
    rgba(127, 19, 156, 0.97),
    rgb(183 12 230 / 97%)
  );
  box-shadow: 0 0 9px 0px rgb(108 19 133 /0.5);
  border-radius: 20px;
}

.work_langs-reverse p {
  margin: 0 auto;
  padding: 0.8rem;
}

.grid__link {
  @apply tw-flex tw-flex-col xxs:tw-flex-row;
  align-items: center;
  padding-left: 0.5rem;
  gap: 0.5rem;
}

.grid__link-reverse {
  @apply tw-flex tw-flex-col tw-w-full xxs:tw-items-center xxs:tw-flex-row;

  gap: 1rem;
}

.grid__img-sm {
  display: none;
  transition: transform 0.3s;
}

.grid__img-sm {
  img {
    @apply tw-cursor-pointer;
  }
}

.grid__img-reverse {
  @apply tw-rounded-lg;
  justify-content: center;
}
.grid__img {
  @apply tw-rounded-lg;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}
img {
  transition: filter 0.5s;
  cursor: pointer;
}
.lazy-img_work {
  filter: blur(10px);
}

// .brew-bud {
//   background-color: #6d197a;
//   filter: drop-shadow(1px 1px 3px darken(#6d197a, 10%));
// }

.brew-img {
  background-color: #e8a0f3;
  filter: drop-shadow(1px 1px 3px darken(#e8a0f3, 50%));
}

// .angular {
//   background-color: #420c7f;
//   filter: drop-shadow(1px 1px 3px darken(#420c7f, 10%));
// }
.angular-img {
  background-color: #f7cd9f;
  filter: drop-shadow(1px 1px 3px darken(#f7cd9f, 50%));
  img {
    overflow: hidden;
    width: 100% !important;
    border-radius: 20px !important;
  }
}

// .pokemon {
//   background-color: #0e8083;

//   filter: drop-shadow(1px 1px 3px darken(#0e8083, 10%));
// }

.pokemon-img {
  background-image: linear-gradient(from bottom right, #3efdf3, #26c0bb);

  filter: drop-shadow(1px 1px 3px darken(#78fcff, 50%));
  img {
    overflow: hidden;
    width: 100% !important;
    border-radius: 20px !important;
  }
}

// .api {
//   background-color: #09527e;
//   filter: drop-shadow(1px 1px 3px darken(#09527e, 10%));
// }

.api-img {
  background-color: #61b8ec;
  filter: drop-shadow(1px 1px 3px darken(#61b8ec, 50%));
  img {
    overflow: hidden;
    width: 100% !important;
    border-radius: 20px !important;
  }
}

// .react {
//   background-color: #373737;
//   filter: drop-shadow(1px 1px 3px darken(#373737, 10%));
// }

.react-img {
  background-color: #deb8fd;
  filter: drop-shadow(1px 1px 3px darken(#deb8fd, 50%));
  img {
    overflow: hidden;
    width: 100% !important;
    border-radius: 20px !important;
  }
}

// .meet {
//   background-color: #d85e5e;
//   filter: drop-shadow(1px 1px 3px darken(#d85e5e, 20%));
// }

.meet-img {
  background-color: #f4a0a0;
  padding: 1rem 0;
  filter: drop-shadow(1px 1px 3px darken(#f4a0a0, 50%));
}

// .chat {
//   background-color: #777e92;
//   filter: drop-shadow(1px 1px 3px darken(#777e92, 10%));
// }

.chat-img {
  background-image: linear-gradient(#eaf4e1, #eaf4e1, #7c859c, #8791ab);
  box-shadow: 0px 1px 9px 0px #9199b1, 0px -1px 10px 0px #68993b;
  img {
    overflow: hidden;
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px !important;
  }
}

.grid__img:hover {
  @apply tw-shadow-xl  tw-transition-all tw-duration-500;
  transform: scale(1.05);
}

.grid__img-sm:hover {
  @apply tw-shadow-xl  tw-transition-all tw-duration-500;
  transform: scale(1.05);
}

.work_links {
  display: flex;
  justify-content: space-around;
}

.btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
    center/1em auto no-repeat;
}

/* ===================================
       Screen sizes up to 500px
  =================================== */
@media all and (max-width: 500px) {
  .work_langs {
    width: 100%;
  }
}
/* ===================================
         Screen sizes up to 604px
    =================================== */
// @media all and (max-width: 604px) {
//   .grid {
//     margin: 1rem 1.5rem;
//   }
// }
/* ====================================================
        Screen sizes between 605px and 1000px
  ====================================================== */
@media all and (min-width: 605px) and (max-width: 1050px) {
  .grid {
    margin: 3rem;
  }
}

/* ===================================
         Screen sizes up to 1000px
    =================================== */
@media all and (max-width: 1050px) {
  .grid {
    grid-gap: 7rem;
  }
  .grid__img {
    display: none;
  }

  .grid__item {
    flex-direction: column;
  }

  .grid__content p {
    padding-right: 0;
  }

  .p_reverse {
    padding-left: 0;
  }

  .reverse {
    flex-direction: column;
  }
  .grid__img-sm {
    display: flex;
    width: 100%;
    flex: 0 1;

    justify-content: center;
    align-items: center;
  }
}
