$primary-color: #234442; /* dark paint green */
$secondary-color: #e29ea9; /* light pink */
$secondary-color-2: #0f2117; /* dark green */
$logo-pink: #a77374; /* dark pink */
$logo-yellow: #d8bc73; /* mustard yellow */
$main-background-color: #fff; /* white */
$light-purple: #d4c2e7;
$light-gray: #d6d6d6;
$black: #000;

* {
  box-sizing: border-box; /* Makes all elements fit on screen */
}

.contact-info {
  padding: 30px 0;
  margin-bottom: 10rem;
  text-align: center;
}

.contact-icons {
  @apply tw-gap-3  tw-grid tw-grid-cols-2 xxs:tw-flex xs:tw-gap-3;

  justify-content: center;

  margin-top: 30px;
}

.social-icons {
  @apply tw-fill-primary tw-border tw-border-primary tw-rounded-xl tw-flex  tw-justify-center tw-items-center hover:tw-bg-primary hover:tw-fill-white;
}

/* ====================================================
        Screen sizes below 415px
  ====================================================== */
@media all and (max-width: 415px) {
  .contact-icons {
    align-items: center;
  }
  .social-icons {
    margin: auto;
  }
}
