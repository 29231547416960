$primary-color: #234442; /* dark paint green */
$secondary-color: #e29ea9; /* light pink */
$secondary-color-2: #0f2117; /* dark green */
$logo-pink: #a77374; /* dark pink */
$logo-yellow: #d8bc73; /* mustard yellow */
$main-background-color: #fff; /* white */
$light-purple: #d4c2e7;
$light-gray: #d6d6d6;
$black: #000;
$teal: #22bdc4;
$body-font: "Lato";
$header-font: "Mona Sans Regular";
$body-font-size: 16px;
$body-font-small: 14px;
$icon-size: 12px;
$header-font-size: 30px;
$secondary-header-font-size: 20px;
$nav-font-size: 18px;
$body-font-weight: 400;
$header-font-weight: 300;
$button-font: #fff;

* {
  box-sizing: border-box; /* Makes all elements fit on screen */
}

/* Header container */
.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 40px;
}

/* logo {jb} */
.logo {
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
  width: 100%;
  max-width: 65px;
  object-fit: contain;
}

/* navigation and logo top */
.page-header__item {
  flex: 0 1 200px; /* sets sizes for logo and nav list */
}

/* target is last item in navigation list */
.page-header__item:last-child {
  flex-grow: 1; /* allows to take up rest of space available */
  text-align: right; /* makes right aligned */
}

.navigation {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* nav. list */
.navigation-list {
  list-style-type: none; /* removes bullet points */
  text-decoration: none;
  font-weight: $header-font-weight;
  font-family: $header-font, sans-serif;
  font-size: $nav-font-size;
}

.navigation-list__item {
  color: $button-font;
  text-decoration: none; /* removes underline from nav list names */
  display: flex; /* aligns nav list into row */
  align-items: center;
  justify-content: center;
  transition: transform 300ms ease-in 0s, box-shadow 300ms ease-in 0s;
  width: 120px;
  height: 45px;
  margin: 0 0.5rem;
  padding: 0 0.5rem;
}

.navigation-list__item:hover,
.navigation-list__item:focus {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $button-font;
  width: 120px;
  height: 45px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 9px 2px rgb(71 24 86);
  border-radius: 20px;
  will-change: transform; // tells browser we will transform for slicker CPU
  transition: transform 150ms ease-in-out 0s, filter 150ms ease-in-out 0s;
  border: solid white;
}

/* BOOTSTRAP OVERRIDE */

.navbar-nav {
  justify-content: flex-end;
}
.navbar {
  width: 100%;
  max-width: 1400px;
}

/* ===================================
       Screen sizes up to 329px
  =================================== */
@media all and (max-width: 329px) {
  .navigation-list {
    margin-right: 0;
    font-size: 16px;
  }
}
/* ===================================
       Screen sizes up to 650px
  =================================== */
@media all and (max-width: 650px) {
  .page-header {
    flex-direction: column;
    align-items: center;
  }

  .page-header__item {
    flex: none;
    margin: 10px 0;
  }
  .navbar-nav {
    justify-content: center;
  }

  .navigation {
    width: 100%;
  }

  .logo {
    margin-top: 20px;
    margin-bottom: 5px;
    max-width: 65px;
  }
}

/* ===================================
       Screen sizes up to 389px
  =================================== */
@media all and (max-width: 389px) {
  .navigation-list__item {
    width: 5rem;
    height: auto;
  }
}
