$primary-color: #234442; /* dark paint green */
$secondary-color: #e29ea9; /* light pink */
$secondary-color-2: #0f2117; /* dark green */
$logo-pink: #a77374; /* dark pink */
$logo-yellow: #d8bc73; /* mustard yellow */
$main-background-color: #fff; /* white */
$light-purple: #d4c2e7;
$light-gray: #d6d6d6;
$black: #000;
$teal: #22bdc4;
$body-font: "Mona Sans Regular";
$header-font: "Mona Sans Regular";
$body-font-size: 16px;
$body-font-small: 14px;
$icon-size: 12px;
$header-font-size: 3rem;
$secondary-header-font-size: 20px;
$nav-font-size: 18px;
$body-font-weight: 300;
$header-font-weight: 400;
$light-font-weight: 200;
$button-font: #fff;
$shadow-color: hsl(176, 32%, 20%, 0.9);
$shadow-hover: hsl(176, 32%, 20%, 0.7);
* {
  box-sizing: border-box; /* Makes all elements fit on screen */
}

body {
  // background: radial-gradient(ellipse at bottom, #7e2e97 0%, #3d144a 80%);

  font-weight: 400;
  font-size: $body-font-size; /* Sets the font size to 16pz */
  line-height: 1.6 !important; /* Sets the line-height to 1.6 */
  height: 100vh; /* gives body a height equivalent to viewport size */
  display: flex; /* allows container footer to have margin:auto and be at bottom of page */
  flex-direction: column; /* makes container a column */
  align-items: center; /* centers everything horizontally */
}

.container {
  margin: auto !important;
}

h1 {
  font-size: $header-font-size;
  font-family: "Source Serif 4", serif;
  font-variant: normal;
  margin: 1rem 0 !important;
}

h2 {
  font-size: $secondary-header-font-size;
  font-variant: normal;
}

h3 {
  @apply tw-text-2xl;
  font-family: "Source Serif 4", serif;
  font-variant: normal;
}

a {
  color: $button-font;
  text-decoration: underline;
}

a:hover,
a:focus {
  /* Overlining when hovering over a link */
  text-decoration: none;
}

a:active {
  /* selceted link */
  color: $logo-yellow; /* yellow */
}

span {
  font-weight: 700;
  background-image: linear-gradient(
    0.9turn,
    #b644d9 0%,

    #74d9c9
  );
  background-clip: text;
  color: transparent;
}

.behind-the-code {
  font-style: italic;
  background-image: none;
  color: #fff;
}

.sm-button {
  width: 60px;
  height: 60px;
}

.form-group input[type="submit"] {
  box-shadow: 1px 2px 5px 1px $black;
}
.tags {
  @apply tw-m-0 tw-text-sm tw-bg-secondary tw-px-2 tw-py-1 tw-rounded-full tw-text-offBlack;
}

.main-btn {
  @apply tw-inline-flex tw-justify-center tw-items-center tw-gap-2 tw-px-3 tw-py-2 tw-rounded-full tw-no-underline hover:tw-bg-primary  hover:tw-text-neutral-50   tw-transition-colors tw-duration-300 tw-ease-in-out tw-text-primary tw-border tw-border-primary tw-font-medium tw-text-base;
}

.main-link {
  @apply tw-inline-flex tw-justify-center tw-items-center tw-gap-2 tw-px-3 tw-py-2 tw-rounded-full tw-no-underline  hover:tw-text-primary hover:tw-font-normal tw-transition-colors tw-duration-300 tw-ease-in-out tw-text-black tw-text-base;
}

.main-link.active {
  @apply tw-text-primary tw-font-normal;
}
