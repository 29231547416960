$primary-color: #234442; /* dark paint green */
$logo-yellow: #d8bc73; /* mustard yellow */
$h1-size: 4rem;

/* home page profile container layout */
.homepage {
  display: flex;
  justify-content: center;
  margin: auto 0; /*centers profile div vertically with auto top-bottom */
}

.homepage__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin-bottom: 2rem;
}
.hidden__intro {
  opacity: 0;
  transform: translateY(-8rem);
}
p {
  @apply tw-text-base xxs:tw-text-lg;
  font-family: "Mona Sans Regular";
}

.hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.homepage__intro {
  @apply tw-flex tw-flex-col tw-gap-3;
  transition: transform 1s ease 400ms, opacity 1s ease 400ms;
  p {
    // line-height: 1.8rem;
    letter-spacing: 0.02rem;
  }
}

.get-started {
  transition: transform 1s ease 2s, opacity 1s ease 2s;
}

/* index page portrait */
.profile__portrait {
  float: left; /* floats image left on page */
  width: 250px;
  height: auto;
  margin-right: 40px; /* space between image and text */
  border-radius: 50%; /* rounds corners of image to make circle */
}

.homepage-button__container {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.homepage-button {
}

#light {
  will-change: transform;
  transition: transform 2s ease 3.5s, opacity 2s ease 3.5s;
}
.left {
  opacity: 0;
  transform: translate3d(-400px, 450px, 0);
}

#middle {
  will-change: transform;
  transition: transform 2s ease 4.5s, opacity 2s ease 4.5s;
}
.center {
  opacity: 0;
  transform: translate3d(0, 450px, 0);
}

#dark {
  will-change: transform;
  transition: transform 2s ease 5.5s, opacity 2s ease 5.5s;
}
.right {
  opacity: 0;
  transform: translate3d(400px, 450px, 0);
}

.card-container {
  perspective: 1000px; /* Adjust as needed */
}

.card-inner {
  transition: transform 0.6s; /* Adjust time as needed */
  transform-style: preserve-3d;
  position: relative;
}

.card-front,
.card-back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: center; /* Ensures flip around the center */
}

.card-back {
  transform: rotateY(180deg);
}

.flipped {
  transform: rotateY(180deg);
}

.frontend__item {
  svg {
    fill: #fff;
    width: 50%;
  }
}

/* ===================================
       Screen sizes up to 500px
  =================================== */
@media all and (max-width: 500px) {
  .homepage__content {
    margin: 1rem 1rem;
  }

  .homepage__content {
    h1 {
      margin: 0;
    }
  }
  .homepage__intro {
    p {
      padding: 0;
    }
  }

  .homepage-button {
    margin-top: 1rem;
  }
}

/* ====================================================
        Screen sizes between 500px and 750px
  ====================================================== */
@media all and (min-width: 500px) and (max-width: 770px) {
  /* .grid {
      grid-template-columns: 1fr 1fr;
    } */

  /* about page container */
  .about {
    flex-direction: column-reverse;
    width: 90%;
    margin: 20px auto; /* centers box in middle of page */
    text-align: center;
  }

  /* portrait on about page */
  .about__portrait {
    max-width: 300px;
    padding: 0 10px;
  }

  #about-resume__button {
    display: none;
  }

  #about-resume__sm-button {
    display: contents;
  }

  .skills-container {
    margin-top: 2rem;
  }
}

/* ===================================
         Screen sizes up to 604px
    =================================== */
@media all and (max-width: 604px) {
  .homepage {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
