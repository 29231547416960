/* footer on all */
.page-footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  transition: transform 2s ease 6s, opacity 2s ease 6s;
}

.hidden {
  opacity: 0;
  transform: translateY(150px);
}

.social-media__icon {
  margin: 0 2rem;
  svg {
    @apply tw-fill-offBlack;
  }
}

.copywrite {
  text-align: center;
  font-size: 0.7rem;
  margin: 0.8rem 0 0 0;
}
/* ====================================================
        Screen sizes below 415px
  ====================================================== */
@media all and (max-width: 415px) {
  .social-media__icon {
    margin: 0 1rem;
  }
}
