$primary-color: #234442; /* dark paint green */
$secondary-color: #e29ea9; /* light pink */
$secondary-color-2: #0f2117; /* dark green */
$logo-pink: #a77374; /* dark pink */
$logo-yellow: #d8bc73; /* mustard yellow */
$main-background-color: #fff; /* white */
$light-purple: #d4c2e7;
$light-gray: #d6d6d6;
$black: #000;
$teal: #22bdc4;
$body-font: "Lato";
$header-font: "PT sans";
$body-font-size: 16px;
$body-font-small: 14px;
$icon-size: 12px;
$header-font-size: 30px;
$secondary-header-font-size: 20px;
$nav-font-size: 18px;
$body-font-weight: 400;
$header-font-weight: 700;
$shadow-color: hsl(176, 32%, 20%, 0.9);

* {
  box-sizing: border-box; /* Makes all elements fit on screen */
}

/* about page container */
.about {
  display: flex;
  align-items: center;
  max-width: 1250px;
  margin: 1.5rem auto 5rem auto;
  flex-direction: row-reverse;
}

/* about page portrait */
.about__portrait {
  max-width: 320px;
  margin-right: 30px;
  margin-left: 30px;
  border-radius: 20px;
}

.lazy-img {
  width: 320px;
  filter: blur(10px);
}

#about__text {
  margin: 0 30px;
  flex: 1 1 200px;
  h1 {
    text-align: center;
  }
}

#skill__header {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 9px 2px rgb(71 24 86);
  border-radius: 15px;
  text-align: right;
  padding: 0.5rem 1rem;
}

.skills {
  display: -webkit-flex;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.skill__item {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  height: 4.375rem;
  width: 4.375rem;
  margin: 0.3rem 0.8rem;
  padding: 0.6rem;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 9px 2px rgb(71 24 86);
  background: linear-gradient(
    0.9turn,
    rgb(0 243 193 / 62%),
    rgb(156 4 198 / 97%),
    #e826ef
  );
  box-shadow: 0 0 9px 0px rgb(108 19 133 /0.5);

  svg {
    fill: #fff;
    width: 90%;
  }
}

.skill__item {
  p {
    display: none;
  }
}

/* <p> in about container */
.about {
  p {
    text-align: left;
    br {
      margin-top: 0.5rem;
    }
  }
}
.photo-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#about-resume__button {
  font-family: $header-font, sans-serif;
  margin-top: 1rem;
}

#about-resume__sm-button {
  font-family: $header-font, sans-serif;
  display: none;
}

/* ====================================================
        Screen sizes between 500px and 750px
  ====================================================== */
@media all and (max-width: 1000px) {
  /* about page container */
  .about {
    flex-direction: column-reverse;
    width: 90%;

    text-align: center;
  }

  /* portrait on about page */
  .about__portrait {
    max-width: 300px;
    padding: 0 10px;
    margin-right: 0;
    margin-left: 0;
  }

  #about__text {
    margin: 0;
  }

  #about-resume__button {
    display: none;
  }

  #about-resume__sm-button {
    display: flex;
    justify-content: center;
  }

  .skill__item {
    p {
      display: block;
      font-size: $icon-size;
      margin: 0;
    }
  }
}
